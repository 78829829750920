import React from "react";
import Container from "../../../components/shared/Container";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import {
  DatoCmsContentBase,
  DatoCmsContentMediaBlock,
  DatoCmsListBlock,
} from "../../types";

import { StructuredText } from "react-datocms";

type Props = {
  section: DatoCmsContentBase;
};

export default function SubscriptionTemplate({ section }: Props): JSX.Element {

  const text = section?.stringContent ? (
    <ReactMarkdown>{section?.stringContent}</ReactMarkdown>
  ) : (
    <>
      {section?.content?.value ? (
        <StructuredText data={section.content} />
      ) : null}
    </>
  );

  return (
    <Container size="max-w-screen-md">
      <div className="text-center">
        <h2 className="inline-block text-xl font-bold md:text-3xl border-b-4 mb-4 border-primary">
          {section.title}
        </h2>
      </div>
      <h3 className="text-xl md:text-3xl">{section.subTitle}</h3>
      <div className="flex flex-wrap -mx-3 mt-3">
        <div
          className={`w-full text-center mb-3 px-3`}
          key={"SUBSCRIPTION_PRICING"}
        >
          <div className="border rounded p-3">
            <h3 className="text-3xl md:text-4xl font-bold">
              £75.00
            </h3>
            <div className="py-1">per month for 12 months</div>
            <div className="text-sm">
              unlimited access
            </div>
          </div>
        </div>
      </div>
      {text ? <div className="mt-2">{text}</div> : null}
    </Container>
  );
}
