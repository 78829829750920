import DefaultTemplate from "./DefaultTemplate";
import Testimonials from "./Testimonials";
import DefaultEvent from "./DefaultEvent";
import Trip from "./Trip";
import VideoBackground from "./VideoBackground";
import SectionWithVideo from "./SectionWithVideo";
import SectionBlock from "./SectionBlock";
import ServiceBlock from "./ServiceBlock";
import ProductList from "./ProductList";
import ProductItem from "./ProductList/ProductItem";
import HutForm from "./HutForm";
import FAQs from "./FAQs";
import Boats from "./Boats";
import PageHeader from "./PageHeader";
import Pricing from "./Pricing";
import Subscription from "./Subscription";

const TemplateHelper: Record<string, (props: any) => JSX.Element> = {
  DefaultTemplate,
  Testimonials,
  DefaultEvent,
  Trip,
  VideoBackground,
  SectionWithVideo,
  SectionBlock,
  ServiceBlock,

  HutForm,

  ProductList,
  ProductItem,

  FAQs,
  Boats,
  PageHeader,
  Pricing,
  Subscription,
};

export const TemplateSectionClasses: Record<string, string> = {
  VideoBackground: "relative overflow-hidden h-[70vh]",
};

export default TemplateHelper;
